@import 'swiper/swiper';
@import 'swiper/modules/pagination/pagination';
@import 'swiper/modules/navigation/navigation';
@import 'swiper/modules/grid/grid';
@import 'swiper/modules/effect-fade/effect-fade';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.text-shadow {
		text-shadow:
			1px 1px 2px #555555,
			-1px -1px 2px #555555;
	}
}

@import 'app/_global-styles/skeleton.scss';

* {
	box-sizing: border-box;
}

html,
body {
	height: 100%;

	&.cdk-global-scrollblock {
		position: static !important;
		width: initial !important;
		overflow-y: hidden;
	}
}

body {
	margin: 0;
	font-family: 'Montserrat', serif;
}

:root {
	--main-color: #03438b;
	--accent-color: #f9ae18;
	--warn-color: #ff5500;
	--swiper-theme-color: #eec216;
}

.swiper-button-prev,
.swiper-button-next {
	font-size: 20px;
	font-weight: bolder;
	text-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
	z-index: 10000;
}

.swiper-pagination {
	bottom: 0 !important;

	&-bullet {
		&.custom.swiper-pagination-bullet-active {
			@apply bg-accentColor #{!important};
		}

		@apply bg-white opacity-100;
		&-active {
			@apply bg-accentColor;
		}

		height: 1.1rem;
		width: 1.1rem;

		@media only screen and (width >= 1024px) {
			& {
				height: 1.3rem;
				width: 1.3rem;
			}
		}
	}
}

.swiper-wrapper {
	height: 100%;
}

.mat-warn {
	color: var(--main-color) !important;
	background-color: var(--accent-color) !important;
	/* text-shadow: 0px 0px 5px #745e0a; */
}

.mat-accent {
	color: var(--warn-color) !important;
}

.mat-checkbox-label {
	white-space: break-spaces;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

mat-radio-button {
	cursor: pointer;
}

app-dropdown-item:first-child li {
	border: none;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
	width: 26px;
	height: 26px;
	border-radius: 13px;
	background-clip: padding-box;
	border: 10px solid transparent;
}

*::-webkit-scrollbar-thumb {
	box-shadow: inset #eec216 0 0 0 10px;
}

// mat slide toggle oskar colouring
.mat-mdc-slide-toggle.mat-accent {
	--mdc-switch-selected-focus-state-layer-color: #e6b340;
	--mdc-switch-selected-handle-color: #e6b340;
	--mdc-switch-selected-hover-state-layer-color: #e6b340;
	--mdc-switch-selected-pressed-state-layer-color: #e6b340;
	--mdc-switch-selected-focus-handle-color: #c29835;
	--mdc-switch-selected-hover-handle-color: #c29835;
	--mdc-switch-selected-pressed-handle-color: #c29835;
	--mdc-switch-selected-focus-track-color: #f9c345;
	--mdc-switch-selected-hover-track-color: #f9c345;
	--mdc-switch-selected-pressed-track-color: #f9c345;
	--mdc-switch-selected-track-color: #f9c345;
}

// mat slide toggle oskar colouring
html {
	--mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
	--mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
	--mdc-checkbox-selected-checkmark-color: white;
	--mdc-checkbox-selected-focus-icon-color: #e6b340;
	--mdc-checkbox-selected-hover-icon-color: #e6b340;
	--mdc-checkbox-selected-icon-color: #e6b340;
	--mdc-checkbox-selected-pressed-icon-color: #e6b340;
	--mdc-checkbox-unselected-focus-icon-color: #212121;
	--mdc-checkbox-unselected-hover-icon-color: #212121;
	--mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
	--mdc-checkbox-selected-focus-state-layer-color: #e6b340;
	--mdc-checkbox-selected-hover-state-layer-color: #e6b340;
	--mdc-checkbox-selected-pressed-state-layer-color: #e6b340;
	--mdc-checkbox-unselected-focus-state-layer-color: black;
	--mdc-checkbox-unselected-hover-state-layer-color: black;
	--mdc-checkbox-unselected-pressed-state-layer-color: black;
	--mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
	--mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

@media screen and (width >= 1024px) {
	.offer-detail-page,
	.reservation-form {
		zoom: 1;

		.fb_reset {
			zoom: 1;
		}
	}

	body {
		zoom: 0.8;

		.fb_reset {
			zoom: 1.2;
		}
	}
}

@media (max-width: 768px) {
	.fb_reset {
		.fb_dialog {
			.fb_dialog_content {
				iframe:nth-child(1) {
					bottom: 80px !important;
				}
			}
		}
		.fb_iframe_widget {
			span > iframe:nth-child(1) {
				bottom: 110px !important;
			}
		}
	}
}

@media print {
	ngx-facebook-messenger {
		visibility: hidden !important;
	}

	#CookiebotWidget {
		visibility: hidden !important;
	}
}

.text-listings {
	color: #000;
	h1 {
		font-size: 18px;
		line-height: 22px;
		font-weight: 900;
	}
	h2 {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
	}
	h3 {
		font-size: 14px;
		line-height: 17px;
		font-weight: 600;
	}
	p {
		font-size: 14px;
		line-height: 17px;
		font-weight: 400;
	}
}
