.skeleton {
	height: 12px;
	margin-bottom: 6px;
	border-radius: 2px;
	background: rgba(130, 130, 130, 0.2);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(8%, rgba(130, 130, 130, 0.2)),
		color-stop(18%, rgba(130, 130, 130, 0.3)),
		color-stop(33%, rgba(130, 130, 130, 0.2))
	);
	background: linear-gradient(
		to right,
		rgba(130, 130, 130, 0.2) 8%,
		rgba(130, 130, 130, 0.3) 18%,
		rgba(130, 130, 130, 0.2) 33%
	);
	background-size: 1000px 100px;
	animation: wave-lines 2s infinite ease-out;
}

.skeleton-square {
	height: 80px;
	border-radius: 5px;
	background: rgba(130, 130, 130, 0.2);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(8%, rgba(130, 130, 130, 0.2)),
		color-stop(18%, rgba(130, 130, 130, 0.3)),
		color-stop(33%, rgba(130, 130, 130, 0.2))
	);
	background: linear-gradient(
		to right,
		rgba(130, 130, 130, 0.2) 8%,
		rgba(130, 130, 130, 0.3) 18%,
		rgba(130, 130, 130, 0.2) 33%
	);
	background-size: 1600px 100px;
	animation: wave-squares 2s infinite ease-out;
}

@keyframes wave-lines {
	0% {
		background-position: -500px 0;
	}
	100% {
		background-position: 500px 0;
	}
}

@keyframes wave-squares {
	0% {
		background-position: -800px 0;
	}
	100% {
		background-position: 800px 0;
	}
}
